import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Button, Form, FormItem, Input, Message, Dialog } from "element-ui";
Vue.config.productionTip = false;
Vue.use(Button).use(Form).use(FormItem).use(Input).use(Dialog);
Vue.prototype.$message = Message;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
