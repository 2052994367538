<template>
  <div id="app">
    <div v-if="!isMobile" class="app-container">
      <div class="header">
        <div class="logo">
          <img src="../assets/logo.png" />
        </div>
        <nav class="navbar">
          <ul class="nav-list">
            <li><div href="" @click="aboutUs">关于我们</div></li>
            <!-- <li><div href="" @click="download('android')">下载莱嗨</div></li> -->
            <li><div href="" @click="callUs">联系我们</div></li>
          </ul>
        </nav>
      </div>
      <div class="content">
        <div class="main">
          <!-- <section id="home" class="hero">
          <div class="hero-content">
            <h2>欢迎来到莱嗨官网</h2>
          </div>
        </section> -->

          <!-- Features Section -->
          <section id="features" class="features">
            <h2>产品特点</h2>
            <div class="features-list">
              <div class="feature-card">
                <img src="../assets/game.png" alt="" />
                <h3>体感运动</h3>
                <p>轻松连接，畅玩无阻</p>
              </div>
              <div class="feature-card">
                <img src="../assets/circle.png" alt="" />
                <h3>运动圈友</h3>
                <p>随时随地分享运动好心情</p>
              </div>
              <div class="feature-card">
                <img src="../assets/shop.png" alt="" />
                <h3>超多好物</h3>
                <p>自由选购</p>
              </div>
              <div class="feature-card">
                <img src="../assets/mine.png" alt="" />
                <h3>跨平台支持</h3>
                <p>
                  支持 微信小程序、iOS、Android 多平台使用，超多功能等你探索
                </p>
              </div>
            </div>
          </section>

          <!-- Download Section -->
          <section id="download" class="download">
            <h2>立即下载莱嗨</h2>
            <div class="buttons">
              <button @click="download('android')" class="btn android">
                <img src="../assets/android.png" alt="" />
                Android
              </button>
              <button
                @mouseover="iosMouseOver"
                @mouseleave="iosMouseLeave"
                class="btn ios"
              >
                <template v-if="!isIos">
                  <img src="../assets/ios.png" alt="" /> iOS
                </template>
                <template v-if="isIos">
                  <div class="code">
                    <img src="../assets/ioscode.png" alt="" />
                  </div>
                  扫描二维码下载
                </template>
              </button>
              <button
                @mouseover="wxMouseOver"
                @mouseleave="wxMouseLeave"
                class="btn ios"
              >
                <template v-if="!isWx">
                  <img src="../assets/wx.png" alt="" /> 微信小程序
                </template>
                <template v-if="isWx">
                  <div class="code">
                    <img
                      src="../assets/wxcode.png"
                      alt=""
                      style="border-radius: 100%"
                    />
                  </div>
                  扫描二维码访问
                </template>
              </button>
            </div>
          </section>

          <!-- Contact Section -->
          <!-- <div class="contact-container">
          <div class="contact-card">
            <h2>联系我们</h2>
            <p>如果您有任何问题，欢迎通过以下方式与我们联系：</p>
            <a class="contact-email" href="mailto:hzhongyikeji2@163.com">
              <span>Email: </span>hzhongyikeji2@163.com
            </a>
          </div>
        </div> -->
          <div class="message">Copyright @ 2024 莱嗨</div>
        </div>
      </div>
    </div>
    <div class="mobile" v-else>
      <div class="header">
        <img src="../assets/logoh.png" />
        <div>
          <img src="../assets/title.png" />
        </div>
      </div>
      <section id="download" class="downloadh">
        <h2 style="color: #fff">立即下载莱嗨</h2>
        <div class="buttons">
          <button
            @click="download('android')"
            class="phonebtn"
            style="color: #fff"
          >
            <img src="../assets/androidh.png" alt="" />
            Android
          </button>
          <button @click="download('ios')" class="phonebtn" style="color: #fff">
            <img src="../assets/iosh.png" alt="" />
            iOS
          </button>
          <button @click="download('wx')" class="phonebtn" style="color: #fff">
            <img src="../assets/wxh.png" alt="" />
            微信小程序
          </button>
        </div>
      </section>
      <div class="content">
        <div class="main">
          <section id="features" class="features">
            <div class="features-list">
              <div class="feature-card">
                <img src="../assets/game.png" alt="" />
                <h3>体感运动</h3>
                <p>轻松连接，畅玩无阻</p>
              </div>
              <div class="feature-card">
                <img src="../assets/circle.png" alt="" />
                <h3>运动圈友</h3>
                <p>随时随地分享运动好心情</p>
              </div>
              <div class="feature-card">
                <img src="../assets/shop.png" alt="" />
                <h3>超多好物</h3>
                <p>自由选购</p>
              </div>
              <div class="feature-card">
                <img src="../assets/mine.png" alt="" />
                <h3>跨平台支持</h3>
                <p>
                  支持 微信小程序、iOS、Android 多平台使用，超多功能等你探索
                </p>
              </div>
            </div>
          </section>

          <!-- Download Section -->
        </div>
      </div>
      <div class="message">Copyright @ 2024 莱嗨</div>
    </div>
  </div>
</template>

<script>
import { getVersion } from "../api/upload";
export default {
  name: "App",
  data() {
    return {
      isMobile: false,
      versionInfo: {},
      isIos: false,
      isWx: false,
    };
  },
  async created() {
    //
    this.isMobile = this.isDelMobile() ? true : false;
    // console.log(this.isMobile);
    const res = await getVersion({ name: "莱嗨", systemType: 1 });
    // console.log(res);
    this.versionInfo = res.result;
    document.title = "莱嗨";
  },
  methods: {
    download(platform) {
      let url = "";
      switch (platform) {
        case "windows":
          url = "https://example.com/download/windows"; // 替换为实际的 Windows 下载链接
          break;
        case "mac":
          url = "https://example.com/download/mac"; // 替换为实际的 Mac 下载链接
          break;
        case "android":
          url = this.versionInfo.url; // 替换为实际的 Android 下载链接
          break;
        case "ios":
          window.open("https://apps.apple.com/cn/app/id6740396476");
          break;
      }
      window.location.href = url; // 跳转到相应平台的下载页面
    },
    isDelMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    iosMouseOver() {
      this.isIos = true;
    },
    iosMouseLeave() {
      this.isIos = false;
    },
    wxMouseOver() {
      this.isWx = true;
    },
    wxMouseLeave() {
      this.isWx = false;
    },
    callUs() {
      this.$router.push("/callus");
    },
    aboutUs() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped lang="scss">
.app-container {
  background: url("../assets/bg.png") no-repeat 100% 100%;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  padding: 0 240px;
  // overflow: hidden;
  display: flex;
  flex-direction: column;
  .message {
    text-align: center;
  }
  .header {
    display: flex;
    align-items: center;
    padding: 30px;
    box-sizing: border-box;
    justify-content: space-between;
    .logo {
      width: 136px;
      height: 60px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .navbar {
      display: flex;
      flex-wrap: wrap;
      .nav-list {
        list-style-type: none;
        padding: 0;
        display: flex;
        align-items: center;
        margin: 0;
        li {
          cursor: pointer;
          // display: inline;
          margin-right: 20px;
          padding: 10px 20px;
          background: #f95527;
          border-radius: 20px;
          div {
            color: #ffffff;
            font-size: 18px;
            font-weight: bold;
            transition: color 0.3s ease;
            text-decoration: none;
          }
          div:hover {
            color: #270d06;
          }
        }
      }
    }
  }
  .content {
    display: flex;
    align-items: center;
    height: 0;
    flex: 1;
    .main {
      flex: 1;
      width: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .features {
        padding: 30px;
        h2 {
          font-size: 32px;
          font-weight: bold;
          margin-bottom: 40px;
          text-align: center;
        }
        .features-list {
          display: flex;
          justify-content: space-between;
        }
        .feature-card {
          box-sizing: border-box;
          padding: 30px;
          border-radius: 10px;
          width: 22%;
          box-shadow: 0 4px 10px rgba(59, 59, 59, 0.1);
          text-align: center;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
          img {
            width: 200px;
            height: 350px;
            border-radius: 10px;
          }
        }
        .feature-card:hover {
          transform: translateY(-10px);
          box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
        }
      }

      .feature-icon {
        font-size: 48px;
        color: #0078d4;
        margin-bottom: 20px;
      }

      .feature-card h3 {
        font-size: 24px;
        font-weight: bold;
        margin: 10px 0;
      }

      .feature-card p {
        font-size: 16px;
        color: #666;
      }
    }
  }
}
.mobile {
  width: 100vw;
  height: 100vh;
  background: url("../assets/bgh.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  .message {
    text-align: center;
    padding: 10px 0;
    color: #ffffff;
    font-size: 12px;
  }
  .header {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 50px 0 30px;
    > img {
      width: 88px;
      height: 88px;
      margin-bottom: 16px;
    }
    div {
      img {
        width: 191px;
        height: 38px;
      }
    }
  }
  .content {
    display: flex;
    align-items: center;
    height: 0;
    flex: 1;
    overflow-y: scroll;
    .main {
      flex: 1;
      width: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .hero {
        color: rgb(0, 0, 0);
        padding: 0 40px;
        background-size: cover;
        text-align: right;
        .hero-content {
          text-align: right;
          h2 {
            font-size: 40px;
            font-weight: bold;
          }
        }
      }
      .features {
        padding: 60px 40px;
        h2 {
          font-size: 32px;
          font-weight: bold;
          margin-bottom: 40px;
          text-align: center;
        }
        .features-list {
          display: flex;
          justify-content: space-around;
          gap: 20px;
          flex-wrap: wrap;
        }
        .feature-card {
          // background: rgba(255, 144, 53, 0.2);
          padding: 30px;
          border-radius: 10px;
          width: 280px;
          box-shadow: 0 4px 10px rgba(59, 59, 59, 0.1);
          text-align: center;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
          img {
            width: 200px;
            height: 350px;
            border-radius: 10px;
          }
        }
        .feature-card:hover {
          transform: translateY(-10px);
          box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
        }
      }

      .feature-icon {
        font-size: 48px;
        color: #0078d4;
        margin-bottom: 20px;
      }

      .feature-card h3 {
        font-size: 24px;
        font-weight: bold;
        margin: 10px 0;
        color: #ffffff;
      }

      .feature-card p {
        font-size: 16px;
        color: #ffffff;
      }
    }
  }
}
// /* Features Section */

// /* Download Section */
.download,
.downloadh {
  text-align: center;
  // padding: 80px 40px;
  h2 {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .buttons {
    display: flex;
    justify-content: space-around;
    .phonebtn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: rgba(218, 218, 217, 0.07);
      border-radius: 50%;
      transition: all 0.3s ease;
      border: none;
      font-size: 14px;
      width: 100px;
      height: 100px;
      img {
        width: 40px;
        height: 40px;
        margin-bottom: 8px;
        border-radius: 100%;
      }
    }
    .phonebtn::after {
      display: none;
    }
  }
}

.btn {
  // padding: 14px 30px;
  border: none;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 187, 142, 0.07);
  border-radius: 50%;
  // color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  font-size: 14px;
  height: 120px;
  width: 120px;
  img {
    width: 40px;
    height: 40px;
    margin-bottom: 8px;
  }
  .code {
    img {
      width: 90px;
      height: 90px;
    }
  }
}

.btn:hover {
  transform: scale(1.05);
}

/* Contact Section */
.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  .contact-card {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
    text-align: center;
  }

  h2 {
    font-size: 2rem;
    color: #2d3436;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.1rem;
    color: #636e72;
    margin-bottom: 20px;
  }

  .contact-email {
    display: inline-block;
    font-size: 1.2rem;
    color: #0984e3;
    text-decoration: none;
    font-weight: bold;
    border-bottom: 2px solid transparent;
    transition: all 0.3s ease;
  }

  .contact-email:hover {
    color: #d63031;
    border-bottom: 2px solid #d63031;
  }

  @media (max-width: 768px) {
    .contact-card {
      padding: 20px;
    }

    h2 {
      font-size: 1.6rem;
    }

    p {
      font-size: 1rem;
    }
  }
}
</style>
